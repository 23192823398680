<template>
    <div id="post-mobile">
        <div class="head">
            <van-nav-bar title="碳问正文" left-text="返回" left-arrow @click-left="clickBack">
                <template #right>
                    <van-icon name="ellipsis" size="18" @click="showArticleSharePannel" />
                </template>
            </van-nav-bar>
        </div>
        <div class="content-body">
            <p class="title" v-if="post.article_info.title">{{ post.article_info.title }}</p>
            <div class="user-info">
                <div class="user-l">
                    <div class="left">
                        <van-image class="avater-img" round fit="cover" :src="post.user_info.avatar || defaultImg" />
                    </div>
                    <div class="right">
                        <div class="user-name">{{ post.user_info.nickname }}</div>
                        <div class="send-time">{{ post.article_info.create_time }}</div>
                    </div>
                </div>
                <van-button round size="small" type="info" :class="['follow-btn', post.is_follow ? 'active' : '']"
                    v-show="userInfo.id !== post.user_info.id" @click="handleFollows"> {{ post.is_follow ? '已关注' : '关注'
                    }}</van-button>
            </div>

            <div class="content" v-html="post.article_info.content"></div>
        </div>
        <div class="divider"></div>
        <div class="comment-box">
            <van-sticky>
                <div class="comment-head">
                    <ul>
                        <li>
                            <span class="head-text">评论</span>
                            <span class="head-count">{{ post.article_comment_count }}</span>
                        </li>
                        <li>
                            <span class="head-text">转发</span>
                            <span class="head-count">{{ post.article_repeat_count }}</span>
                        </li>
                        <li>
                            <span class="head-text">赞</span>
                            <span class="head-count">{{ post.article_like_count }}</span>
                        </li>
                    </ul>
                </div>
            </van-sticky>
            <div class="comment" v-if="commentList.length">
                <div v-for="item in commentList" :key="item.comment_id" class="post-comment-list-body">
                    <div class="post-comment-list-body-item">
                        <div class="list-item-logo-name-time">
                            <van-image class="user-logo" round fit="cover" :src="item.user_info.avatar || defaultImg" />
                            <div class="user-name">
                                {{ item.user_info ? item.user_info.nickname : "" }}
                            </div>
                        </div>
                        <div class="list-item-content" @click="replyuserComment(item)">{{ item.comment_content }}</div>
                        <div class="list-item-parent-content" v-if="item.parent_comment"
                            @click="replyuserComment(item.parent_comment)">
                            <span class="parent-name">{{ item.parent_comment ? item.parent_comment.user_info_name : ''
                            }}</span>
                            :{{ item.parent_comment ? item.parent_comment.comment_content : " " }}
                        </div>
                        <div class="list-item-bottom">
                            <div class="time">{{ item.create_time }}</div>
                            <div class="list-item-action">
                                <div class="like" @click.stop="handleCommentLike(item)">
                                    <img v-if="!item.is_like" src="~@/assets/images/like.svg" alt />
                                    <img v-else src="~@/assets/images/like-active.svg" alt />
                                    {{ item.comment_like_count }}
                                </div>
                                <div>
                                    <img src="~@/assets/images/reply.svg" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-comment" v-if="!commentList.length">
                    <van-empty description="暂无评论,做第一个评论的人吧" />
                    <!-- <div class="no-comment-text">暂无评论,做第一个评论的人吧</div> -->
                </div>
                <!-- 发布评论 -->
                <ArticleComment ref="articleComment" :postDetailInfo="post" :articleId="$route.query.id"
                    @getAllCommentList="getAllCommentList" @getPostInfo="getPostInfo"></ArticleComment>
                <div class="message">
                    <!-- 出现关注消息提示-->
                    <MessagePoint mesageTxt="关注成功" :show="showFollow"></MessagePoint>
                    <!-- 出现取消关注消息提示-->
                    <MessagePoint mesageTxt="取消关注" :show="cancelFollow"></MessagePoint>
                    <!-- 出现删除帖子消息提示-->
                    <MessagePoint mesageTxt="删除成功" :show="delePost"></MessagePoint>
                </div>
                <van-share-sheet v-model="showShare" :options="options" @select="selectPannel" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { relativeTime } from '@/utils/util.js';
import ArticleComment from '@/components/Mobile/Comment'
import MessagePoint from '@/components/Mobile/MessagePoint'
export default {
    name: "Post",
    components: {
        ArticleComment,
        MessagePoint,
    },
    data() {
        return {
            defaults: '',

            post: {
                article_info: {

                },
                user_info: {

                }
            },
            titles: " ",
            commentList: [],
            currentCommentId: "",
            // 默认头像
            defaultImg: require('@/assets/images/userLogo.jpg'),
            //文章转发弹窗显示
            repeatDialogVisible: false,

            //登录
            dialogVisible: false,
            loginType: "account_password",
            showFollow: false, //关注提示
            cancelFollow: false, //取消关注消息提示
            showShare: false,
            delePost: false, // 删除帖子消息提示
            options: [
                { name: '删除', icon: require('@/assets/images/delete-icon.svg') },
                { name: '微信', icon: 'wechat' },
                { name: '微博', icon: 'weibo' },
                { name: '复制链接', icon: 'link' },
                { name: '分享海报', icon: 'poster' },
            ],
        };
    },
    computed: {
        ...mapState(['token', 'userInfo']),
    },
    activated() {
        if (!this.$route.query.id) return;
        this.getPostInfo();
        this.getAllCommentList();
    },
    methods: {
        // 获取贴吧详情信息
        async getPostInfo() {
            // /api/article/getpostarticleinfo
            let { data: res } = await this.api
                .postFormAPISM(
                    {
                        post_article_id: this.$route.query.id
                    },
                    "/article/getpostarticleinfo"
                )
            this.post = res.data;
            if (this.post.article_info.title) {
                document.title = this.post.article_info.title;
            }
        },

        getAllCommentList() {
            this.api
                .postFormAPISM(
                    {
                        post_article_id: this.$route.query.id
                    },
                    "/article/getcommentlist"
                )
                .then(res => {
                    console.log('444',res.data.data.comment_list);
                    this.commentList = res.data.data.comment_list.map(item=>{
                        return{
                            ...item,
                            create_time:relativeTime(item.create_time)
                        }
                    });
                    // 转化时间格式
                    // this.commentList.forEach((item) => {
                    //     item.create_time = relativeTime(item.create_time);
                    // });
                })
                .catch(error => {
                    console.log("error", error);
                });
        },
        // 点击关注
        async handleFollows() {
            // 当用户已经登录时
            if (this.token) {
                //  想要关注的用户id
                const target_user_id = this.post.user_info.id;
                let is_follow = this.post.is_follow;
                // 发起关注请求
                await this.api.
                    postFormAPISM(
                        {
                            target_user_id,
                            is_follow: !is_follow,
                            remark_name: ""
                        },
                        "/user/follow"
                    );
                if (is_follow) {
                    // 弹出取消关注提示
                    this.cancelFollow = true;
                    setTimeout(() => {
                        this.cancelFollow = false;
                    }, 500);
                }
                else {
                    // 弹出关注提示
                    this.showFollow = true;
                    setTimeout(() => {
                        this.showFollow = false;
                    }, 500);
                }
                // 切换是否关注
                this.post.is_follow = !this.post.is_follow;
                //  重新获取帖子详情信息
                this.getPostInfo();;
            }
            else {
                // 未登录，跳转回登录页面
                this.$router.push('/login');
            }
        },
        //  评论点赞
        async handleCommentLike(comment) {
            // 当用户已经登录时
            if (this.token) {
                // 当前帖子的id
                const comment_id = comment.comment_id;
                let is_like = comment.is_like;
                // 调用点赞接口
                await this.api.
                    postFormAPISM(
                        {
                            post_article_comment_id: comment_id,
                            is_like: !is_like,
                        },
                        "/article/commentlike"
                    );

                // 切换是否点赞
                comment.is_like = !comment.is_like;
                //  重新获取评论列表
                this.getAllCommentList();
            }
            else {
                // 未登录，跳转回登录页面
                this.$router.push('/login');
            }
        },

        // 回复子评论
        replyuserComment(articleInfo) {
            console.log(articleInfo);
            if (this.token) {
                // 调用子组件回复子评论方法
                this.$refs.articleComment.replyNextComment(articleInfo);
                // 刷新帖子信息
                this.getAllCommentList();
            }
            else {
                // 未登录，跳转回登录页面
                this.$router.push('/login');
            }
        },
        // 清除帖子详情信息数据
        clearPostDetailInfo() {
            this.$router.back();

            // 清楚评论输入框中的placsholder
            this.$refs.articleComment.placeholders = '';
            // // 清楚评论输入框中输入的评论
            this.$refs.articleComment.commentText = '';
            this.$refs.articleComment.commentShow = true;
            this.$refs.articleComment.articleInfo = null;

        },
        // 点击返回
        clickBack() {
            this.clearPostDetailInfo();
        },
        // 展示分享面板
        showArticleSharePannel() {
            this.showShare = true;
            // 如果帖子是自己发布的，显示删除按钮，否则不显示
            if (this.post.user_info.id !== this.userInfo.id) {
                this.options = this.options.filter(val => {
                    return val.name !== '删除';
                });
            }
            else {
                if (this.options[0].name !== '删除') {
                    this.options.unshift(
                        { name: '删除', icon: require('@/assets/images/delete-icon.svg') },
                    );
                }
            }

        },
        // 选择分享面板中的某一个
        async selectPannel(option) {
            // 删除评论
            if (option.name === '删除') {
                const article_id = this.post.article_info.id;
                // 调用删除帖子接口
                await this.api.
                    postFormAPISM(
                        {
                            post_article_id: article_id,
                        },
                        "/article/delete"
                    );
                this.delePost = true;
                setTimeout(() => {
                    this.delePost = false;
                }, 500);
                this.showShare = false;
                // 返回帖子列表
                this.$router.back();
            }

        },
    }
};
</script>

<style lang="less" scoped>
#post-mobile {
    .content-body {
        padding: 0 18px;

        .title {
            font-size: 18px;
            color: #00000f;
            font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
            padding-top: 20px;
        }

        .user-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;

            .follow-btn {
                box-sizing: border-box;
                width: 70px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                // padding: 8px 16px;
                background-color: #27a1ff;
                // border-radius: 18px;
                color: #fff;
                border: 1px solid #27a1ff;
                font-size: 14px;

                &.active {
                    color: #ccc;
                    background-color: #f3f7fa;
                    border: 1px solid #f3f7fa;
                }
            }

            .user-l {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    margin-right: 10px;
                    width: 35px;
                    height: 35px;

                    .right {
                        height: 35px;
                    }
                }

                .user-name {
                    font-size: 16px;
                }

                .send-time {
                    font-size: 10px;
                    color: #a7a6a6;
                }
            }
        }

        .content {
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 16px;

            /deep/ img {
                width: 100%;
            }
        }
    }

    .divider {
        width: 100%;
        height: 5px;
        background: #ccc;
        opacity: .4;
    }

    .comment-box {
        /deep/ .van-sticky--fixed {
            background: #fff;
            box-shadow: 0 0 0 1px #f5f5f5;
        }

        &>.message {
            &>.van-popup {
                position: fixed;
                width: 84px;
                padding: 4px 16px;
                border-radius: 50px;
                font-size: 12px;
                margin: 0 calc(50% - 50px);
                top: 45px;
                background-color: rgb(67, 122, 239);

                &>i {
                    display: none;
                }
            }
        }

        /deep/ .van-overlay {
            background-color: rgba(0, 0, 0, .2);
        }

        /deep/ .van-share-sheet__icon {
            width: 35px;
            height: 35px;
            margin: 0 20px;
        }

        .comment-head {
            padding: 0 18px;
            width: 60%;

            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;

                li {
                    flex: 1;

                    .head-text {
                        font-size: 15px;
                        color: #33353c;
                    }

                    .head-count {
                        font-size: 10px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .comment {
            padding: 0 18px;
            overflow: hidden;

            .post-comment-list-body {
                display: flex;
                flex-flow: column nowrap;

                &:last-child {
                    margin-bottom: 62px;
                }

                &>.post-comment-list-body-item {
                    padding: 15px 0 10px;
                    border-top: 1px solid #edf0f5;

                    &>.list-item-logo-name-time {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;

                        &>.user-logo {
                            width: 35px;
                            height: 35px;
                        }

                        &>.user-name {
                            flex: 1;
                            font-size: 15px;
                            color: #33353c;
                            font-weight: 700;
                            padding: 0 0 0 10px;
                        }

                        &>.ellipsis {
                            width: 20px;
                            text-align: right;
                            font-size: 12px;
                            color: #909499;
                        }



                    }

                    &>.list-item-content {
                        padding: 0 0 0 45px;
                        font-size: 14px;
                        color: #33353c;
                        line-height: 24px;
                    }

                    &>.list-item-parent-content {
                        background-color: #f9f9f9;
                        margin: 0 0 0 45px;
                        font-size: 13px;
                        color: #a7a6a6;
                        padding: 15px;
                        border-radius: 8px;

                        .parent-name {
                            color: #27a1ff;
                            font-size: 13px;

                        }
                    }

                    &>.list-item-bottom {
                        padding: 10px 0 0 45px;
                        display: flex;
                        // flex-flow: row nowrap;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 12px;

                        &>.list-item-action {
                            display: flex;
                            // flex-flow: row nowrap;
                            align-items: center;

                            // padding: 8px 0 0 42px;
                            &>div {
                                display: flex;
                                align-items: center;
                                // height: 18px;
                                padding-right: 14px;
                                cursor: pointer;

                                &:not(:first-child) {
                                    padding-left: 14px;
                                    border-left: 1px solid rgba(217, 217, 217, 0.6);
                                }
                            }

                            img {
                                width: 15px;
                                height: 15px;
                                margin-right: 8px;
                            }



                            &>svg {
                                width: 16px;
                                height: 16px;

                                &.reply {
                                    width: 14px;
                                    height: 14px;
                                }
                            }

                            &>span {
                                font-size: 13px;
                                color: #909499;
                                padding: 0 0 0 8px;
                            }
                        }
                    }
                }
            }

            .no-comment {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin: 100px 0;

            //     // background-color:#f7f7f7;
            //     .no-comment-text {
            //         color: #a7a6a6;
            //         font-size: 16px;
            //     }
            // }
        }
    }
}
}
</style>
