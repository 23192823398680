<template>
  <div class="article-comments">
    <!-- 发布评论 -->
    <div :class="commentShow ? 'art-cmt-container-1' : 'art-cmt-container-2'">
      <!-- 底部添加评论区域 - 1 -->
      <div class="add-cmt-box van-hairline--top">
        <van-icon class-prefix="iconfont" name="share" size="24px" @click="$router.push({path: '/repeat',query: {post_article_id: articleId},})" />
        <div class="ipt-cmt-div" @click="hShowCommentArea">写评论...</div>
        <div class="icon-box">
          <van-badge :content="postDetailInfo.article_comment_count !== 0 ? postDetailInfo.article_comment_count : ''" max="99"
            color="rgb(255, 119, 0)">
            <van-icon class-prefix="iconfont" name="reply" size="20px" />
          </van-badge>
          <van-badge :content="postDetailInfo.article_like_count !== 0 ? postDetailInfo.article_like_count : ''" max="9999"
            color="rgb(255, 119, 0)">
            <van-icon v-show="!postDetailInfo.is_like" class-prefix="iconfont" name="like" size="20px"
              @click="handleAticleLike()" />
            <van-icon v-show="postDetailInfo.is_like" color="rgb(255, 119, 0)" class-prefix="iconfont" name="like-active"
              size="20px" @click="handleAticleLike()" />
          </van-badge>

        </div>
      </div>

      <!-- 底部添加评论区域 - 2 -->
      <div class="cmt-box van-hairline--top" v-show="!commentShow">
        <div class="cmt-box-top">
          <textarea ref="txt" :placeholder="placeholders || '说说你的看法...'" v-model.trim="commentText"
            @blur="hBlur"></textarea>
          <van-button type="default" @click="hAddComment" :disabled="!commentText">发布</van-button>
        </div>
      </div>
    </div>
    <!-- /发布评论 -->

    <!-- 发表评论成功提示 -->
    <van-notify v-model="showComment" type="success">
      <van-icon name="bell" style="margin-right: 4px;" />
      <span>评论成功</span>
    </van-notify>
  </div>
</template>
  
<script>
import { mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'//编辑器引入
export default {
  name: 'ArticleComment',
  props: ['postDetailInfo', 'articleId'],
  data() {
    return {
      //发表的评论
      commentText: '',
      commentShow: true,
      placeholders: '',
      //评论成功弹出框显示
      showComment: false,

      // 子评论信息
      articleInfo: null,
    }
  },
  computed: {
    ...mapState(['token']),
  },
  mounted() {
  },
  methods: {
    // 用户点击添加
    async hAddComment() {
      if (!this.commentText) return;
      //给文章评论
      if (!this.articleInfo) {
        await this.api
        .postFormAPISM(
          {
            post_article_id: this.articleId,
            comment_content:this.commentText,
          },
          "/article/comment"
        );
       
      }
      // 给父评论评论
      else {
        await this.api
        .postFormAPISM(
          {
            post_article_id: this.articleId,
            comment_content:this.commentText,
            parent_comment_id : this.articleInfo.comment_id
          },
          "/article/comment"
        );
      }
      this.showComment = true;
      setTimeout(() => {
        this.showComment = false;
      }, 500);
      //刷新评论列表
      this.$emit('getAllCommentList')
    },
    // 输入框失去焦点
    hBlur() {
      // 回到状态1
      // this.$nextTick(() => {
      //  this.commentShow = true
      //})
      setTimeout(() => {
        this.commentShow = true
      })
      this.placeholders=''
    },
    // 用户点击了发表评论
    hShowCommentArea() {
      // 状态2会显示出来
      this.commentShow = false // 通知视图 等会 去更新
      // $nextTick(回调函数)  // 更新视图之后，去执行回调函数
      this.$nextTick(() => {
        // 让输入框获取焦点
        this.$refs.txt.focus()
      })
    },
    //给文章点赞
    async handleAticleLike() {
      // 当用户已经登录时
      if (this.token) {
        const aticle_id = this.postDetailInfo.article_info.id;
        let is_like = this.postDetailInfo.is_like;
        // 调用点赞接口
        await this.api.
          postFormAPISM(
            {
              post_article_id: aticle_id,
              is_like: !is_like,
            },
            "/article/like"
          );

        // 切换是否点赞
        this.postDetailInfo.is_like = !this.postDetailInfo.is_like;
        //通知父组件，刷新视图
        this.$emit('getPostInfo');
      }
      else {
        // 未登录，跳转回登录页面
        this.$router.push('/login');
      }
    },
    //回复子评论
    replyNextComment(articleInfo) {
      // 回复不同的用户时，清除上一次的输入框回复内容
      if ( this.articleInfo !== articleInfo) 
      {
        this.commentText = '';
      }
      this.articleInfo = articleInfo;
      console.log('replyNextComment', this.articleInfo);
         // 输入框中指出要评论的人
         const  nickName = articleInfo.user_info_name || articleInfo.user_info.nickname;
        this.placeholders = '回复@' + nickName;
      this.commentShow = false;

    }

  },
}
</script>
  
<style scoped lang='less'>
.article-comments {
  &>.van-popup {
    position: fixed;
    width: 84px;
    padding: 4px 16px;
    border-radius: 50px;
    font-size: 12px;
    margin: 0 calc(50% - 50px);
    top: 45px;
    background-color: rgb(67, 122, 239);

    &>i {
      display: none;
    }
  }
}

// 发表评论的区域是固定在下端的
.publish-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

// 给发表评论区空出地方
.van-list {
  margin-bottom: 45px;
}

/*美化 - 文章详情 - 底部的发布评论-样式 */
// 外层容器
.art-cmt-container-1 {
  padding-bottom: 46px;
}

.art-cmt-container-2 {
  padding-bottom: 80px;
}

// 发布评论的盒子 - 1
.add-cmt-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  line-height: 46px;
  padding-left: 10px;

  .ipt-cmt-div {
    flex: 1;
    border: 1px solid #efefef;
    border-radius: 15px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    padding-left: 15px;
    margin-left: 10px;
    background-color: #f8f8f8;
  }

  .icon-box {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    line-height: 0;
  }
}

.child {
  width: 20px;
  height: 20px;
  background: #f2f3f5;
}

// 发布评论的盒子 - 2
.cmt-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  background-color: white;

  &>.cmt-box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 80px;

    textarea {
      flex: 1;
      height: 66%;
      border: 1px solid #efefef;
      background-color: #f8f8f8;
      resize: none;
      border-radius: 6px;
      padding: 5px;
    }

    .van-button {
      height: 100%;
      border: none;
    }
  }

  &>.upload-img {
    margin-right: 10px;

    /deep/ .van-uploader__upload {
      width: 25px;
      height: 25px;

      .van-uploader__upload-icon {
        font-size: 18px;
      }
    }

  }
}
</style>